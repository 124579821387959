import { combineReducers } from "redux";
import { routerReducer } from "connected-next-router";
import checkoutPagesReducer from "repoV2/features/CheckoutPages/utils/reducers/CheckoutPages.reducers";
import formDataReducer from "repoV2/features/Common/modules/FormData/utils/reducer";
import customCodeReducer from "repoV2/features/CustomCodes/utils/reducer";
import bonusOfferingsReducer from "repoV2/features/BrandedCommunity/utils/reducers/BonusOfferings.reducers";
import creatorDetailsReducer from "repoV2/features/CreatorDetails/redux/reducer";

import host from "./host";
import event from "./event";
import user from "./user";
import utils from "./utils";
import componentProps from "./componentProps";

/**
 * - `REPO_V2_APP_REDUCERS` Defines reducers built in repoV2
 * - existing reducers for code outside of repov2 will be there in `APP_REDUCERS`
 * ### Why?
 * - To keep distinction b/w repov2 and src reducers
 * - Try Adding new Reducers to repov2 features folder so that migrating old code is easy
 */
const REPO_V2_APP_REDUCERS = {
    bonusOfferings: bonusOfferingsReducer,
    formData: formDataReducer,
    checkoutPages: checkoutPagesReducer,
    customCodes: customCodeReducer,
    creatorDetails: creatorDetailsReducer,
};

/**
 * Global App level Reducers
 */
const APP_REDUCERS = {
    host,
    event,
    user,
    utils,
    componentProps,
    router: routerReducer,
    ...REPO_V2_APP_REDUCERS,
};

export default combineReducers(APP_REDUCERS);
